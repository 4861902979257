import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import validateAgentCode from '../../../helpers/validate-agent-code';
import {
  fetchAgentProfile,
  setAgentProfileRatePlanAssociated,
} from '../../../redux/slices/bookingsSlice/bookingsSlice';

const useValidateSelectRoomParams = (
  hotel,
  defaultValues,
  validateSpecialCode,
  validateRateCode,
  axios
) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [parametersValidated, setParametersValidated] = useState({
    agentId: !defaultValues.agentId,
    specialCode: !defaultValues.specialCodeValue,
    rateCode: !defaultValues.rateCode,
  });

  // add refs to track that the validation has started for each of the params
  // and that the validation has finished
  const agentIdCheck = useRef(false);
  const specialCodeCheck = useRef(false);
  const rateCodeCheck = useRef(false);
  const initialCheck = useRef(false);

  const handleValidateAgentCode = useCallback(async () => {
    const { valid, ratePlanAssociated = false } = await validateAgentCode(
      {
        productCode: hotel.productCode,
        startDate: defaultValues.startDate,
        endDate: defaultValues.endDate,
        agentId: defaultValues.agentId,
        numAdults: defaultValues.guestsPerRoom[0].numAdults,
      },
      axios
    );
    dispatch(setAgentProfileRatePlanAssociated(ratePlanAssociated));

    if (valid) {
      await dispatch(fetchAgentProfile(defaultValues.agentId, axios));
    } else {
      const params = new URLSearchParams(searchParams);
      params.delete('agentId');
      setSearchParams(params);
    }

    setParametersValidated((prev) => ({ ...prev, agentId: true }));
  }, [
    axios,
    defaultValues.agentId,
    defaultValues.endDate,
    defaultValues.guestsPerRoom,
    defaultValues.startDate,
    dispatch,
    hotel.productCode,
    searchParams,
    setSearchParams,
  ]);

  const handleValidateSpecialCode = useCallback(async () => {
    await validateSpecialCode();
    setParametersValidated((prev) => ({ ...prev, specialCode: true }));
  }, [validateSpecialCode]);

  const handleValidateRateCode = useCallback(async () => {
    await validateRateCode();
    setParametersValidated((prev) => ({ ...prev, rateCode: true }));
  }, [validateRateCode]);

  useEffect(() => {
    if (initialCheck.current) return;
    initialCheck.current = true;

    if (
      defaultValues.agentId &&
      !parametersValidated.agentId &&
      !agentIdCheck.current
    ) {
      handleValidateAgentCode();
    }

    if (
      defaultValues.specialCodeValue &&
      !parametersValidated.specialCode &&
      !specialCodeCheck.current
    ) {
      handleValidateSpecialCode();
    }

    if (
      defaultValues.rateCode &&
      !parametersValidated.rateCode &&
      !rateCodeCheck.current
    ) {
      handleValidateRateCode();
    }

    specialCodeCheck.current = true;
    rateCodeCheck.current = true;
    agentIdCheck.current = true;
  }, [
    handleValidateAgentCode,
    defaultValues.agentId,
    defaultValues.specialCodeValue,
    defaultValues.rateCode,
    handleValidateSpecialCode,
    handleValidateRateCode,
    parametersValidated.agentId,
    parametersValidated.specialCode,
    parametersValidated.rateCode,
  ]);

  const allParamsValidated = Object.values(parametersValidated).every(
    (value) => value
  );

  return {
    parametersValidated,
    allParamsValidated,
  };
};

export default useValidateSelectRoomParams;
