import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import useLocalisedMoment from '../../../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import getDayStyles from '../../../../helpers/get-day-styles';
import { Price } from './components';

const DayContents = ({
  day,
  daysAvailability,
  availableSelectionRange,
  isLoading,
  printPrice,
  numRooms,
  selectedRange,
  restrictedDates,
  side,
}) => {
  const { t } = useTranslate();
  const moment = useLocalisedMoment();
  const date = daysAvailability.find(
    ({ date }) => date === moment(day).format('YYYY-MM-DD')
  );

  const { className, tooltipContent } = useMemo(
    () =>
      getDayStyles(
        date,
        moment,
        t,
        availableSelectionRange,
        daysAvailability,
        selectedRange,
        restrictedDates
      ),
    [
      date,
      moment,
      t,
      availableSelectionRange,
      daysAvailability,
      selectedRange,
      restrictedDates,
    ]
  );

  if (!isLoading && daysAvailability.length > 0) {
    const date = daysAvailability.find(
      ({ date }) => date === moment(day).format('YYYY-MM-DD')
    );

    return (
      <div
        id={`day-${date?.date}`}
        data-testid={`day-${date?.date}`}
        className={className}
      >
        <div className="clipPathDiv"></div>
        <div className="d-flex flex-column align-items-center">
          {/* consistent id to select during e2e testing */}
          <div id={`${side}-${day.format('DD')}`}>{day.format('DD')}</div>

          {date?.minimumPrice?.value && numRooms === 1 && (
            <Price
              date={date}
              day={day}
              tooltipContent={tooltipContent}
              printPrice={printPrice}
              side={side}
            />
          )}

          {tooltipContent && date?.date && (
            <UncontrolledTooltip
              placement="top"
              target={`day-${date?.date}`}
              style={{ textAlign: 'left' }}
            >
              {tooltipContent}
            </UncontrolledTooltip>
          )}
        </div>
      </div>
    );
  } else {
    return <div>{day.format('DD')}</div>;
  }
};

DayContents.propTypes = {
  day: PropTypes.object,
  daysAvailability: PropTypes.array,
  availableSelectionRange: PropTypes.array,
  restrictedDates: PropTypes.array,
  selectedRange: PropTypes.array,
  isLoading: PropTypes.bool,
  printPrice: PropTypes.func,
  numRooms: PropTypes.number,
  side: PropTypes.oneOf(['left', 'right']),
};

export default DayContents;
