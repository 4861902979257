import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import 'moment/locale/fr';
import 'moment/locale/zh-cn';
import 'moment/locale/en-gb';
import 'moment/locale/es';

const langMap = {
  'en-us': 'en-gb',
  'zh-cn': 'zh-cn',
  'fr-fr': 'fr',
  'es-xm': 'es',
};

const useLocalisedMoment = () => {
  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const localisedMoment = useMemo(() => {
    const lang = langMap[currentLanguage] || 'en-gb';
    moment.locale(lang);
    return moment;
  }, [currentLanguage]);

  return localisedMoment;
};

export default useLocalisedMoment;
