import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Alert } from 'reactstrap';
import styles from './useMessage.module.css';

const Message = ({ open, message, color, className, onClose }) => (
  <Alert
    color={color}
    isOpen={open}
    className={`${className} ${styles[color]}`}
    toggle={onClose}
  >
    {message}
  </Alert>
);

Message.propTypes = {
  color: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default function useMessage() {
  const [shown, setShown] = useState(false);
  const [props, setProps] = useState({});

  return [
    ({ className = '' }) => (
      <Message
        open={shown}
        color={props.color}
        message={props.message}
        className={className}
        onClose={() => setShown(false)}
      />
    ),
    useCallback((message, color = 'warning', fixed = true) => {
      setProps({ color, message });
      setShown(true);
      if (!fixed) {
        setTimeout(() => setShown(false), 6000);
      }
    }, []),
    useCallback(() => setShown(false), []),
  ];
}
