import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { CHINA_VERSION } from '../../globals';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './LoadingPaymentModal.module.css';

const LoadingPaymentModal = ({ open }) => {
  const { t } = useTranslate();
  return (
    <Modal
      isOpen={open}
      centered
      contentClassName={styles.LoadingPaymentModal__modalWrapper}
    >
      <ModalBody className={styles.LoadingPaymentModal__animation}>
        <div>
          <div className={styles.LoadingPaymentModal__text}>
            {t('Please wait while we')}{' '}
            {CHINA_VERSION ? t('pre-order') : t('confirm')} {t('your booking')}
            ...
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
LoadingPaymentModal.propTypes = {
  open: PropTypes.bool,
};

export default LoadingPaymentModal;
