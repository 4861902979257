import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';
import styles from './SkeletonLoading.module.css';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoading = () => {
  return (
    <Row className={styles.SkeletonLoading__container}>
      <Col md="5 p-0">
        <Skeleton
          height={380}
          className={styles.SkeletonLoading__leftColumn}
          containerTestId="skeleton"
        />
      </Col>
      <Col md="7 p-0" className={styles.SkeletonLoading__rightColumn}>
        <Skeleton height={380} />
      </Col>
    </Row>
  );
};

export default SkeletonLoading;
