import getMinStayExclusions from './get-min-stay-exclusions';

const getSelectedRangeAfterRestrictions = (
  availableSelectionRange,
  daysAvailability,
  moment
) => {
  if (!availableSelectionRange?.length) return [];
  if (!daysAvailability?.length) return [];

  const startDate = availableSelectionRange[0];
  let minStayExclusions = [];
  for (const currentDate of availableSelectionRange) {
    const { metadata } = daysAvailability.find(
      ({ date }) => date === currentDate
    );

    // handle min stay arrive
    if (currentDate === startDate && metadata?.MinStayArrive) {
      const minStayArriveExclusions = getMinStayExclusions(
        startDate,
        0,
        metadata.MinStayArriveValue,
        moment
      );

      minStayExclusions = minStayArriveExclusions;
    }

    // handle min stay through
    const minStayThroughExclusionsForDate = getMinStayExclusions(
      startDate,
      availableSelectionRange.indexOf(currentDate),
      metadata.MinStayThroughValue,
      moment,
      true
    );

    minStayExclusions = [
      ...minStayExclusions,
      ...minStayThroughExclusionsForDate,
    ];
  }

  // remove the dates inside of minStayExclusions from the availableSelectionRange
  // dont remove the start date though!

  // lets have 2 arrays
  // one for the available selection range after restrictions are applied
  // and another for the restricted dates that we have binned
  // this way, we can use it elsewhere instead of recalculating

  const availableSelectionRangeWithRestrictions =
    availableSelectionRange.filter(
      (date) => !minStayExclusions.includes(date) || date === startDate
    );

  const restrictedDates = availableSelectionRange.filter(
    (date) => !availableSelectionRangeWithRestrictions.includes(date)
  );

  return [availableSelectionRangeWithRestrictions, restrictedDates];
};

export default getSelectedRangeAfterRestrictions;
