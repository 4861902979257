import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phone: '',
  hotels: {},
  hotelsRateContent: {},
  bookingRateContent: {},
  agents: {},
};

export const belmondSlice = createSlice({
  initialState,
  name: 'belmondSlice',
  reducers: {
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setHotels: (state, action) => {
      state.hotels = action.payload.reduce((acc, hotel) => {
        acc[hotel.productCode] = hotel;
        return acc;
      }, {});
    },

    setHotelsRateContent: (
      state,
      { payload: { productCode, rateCode, rateContent, bookingId } }
    ) => {
      const targetState = bookingId
        ? state.bookingRateContent
        : state.hotelsRateContent;

      const targetProperty = bookingId || rateCode;

      targetState[productCode] = {
        ...targetState[productCode],
        [targetProperty]: rateContent,
      };

      const { agentId } = rateContent;

      if (agentId && rateContent.commissionablePercentage) {
        if (!state.agents[agentId]) {
          state.agents[agentId] = {};
        }

        const currentCommission = state.agents[agentId].commission || 0;
        state.agents[agentId].commission = Math.max(
          currentCommission,
          rateContent.commissionablePercentage
        );
      }
    },
  },
});

export const fetchHotels = (axios, countryCode) => async (dispatch) => {
  try {
    let { data } = await axios.get('/properties', {
      params: { country_code: countryCode },
    });

    dispatch(setHotels(data));
  } catch (e) {
    console.error(e);
  }
};

export const fetchHotelsRateContent =
  (params, moment, axios, bookingId) => async (dispatch) => {
    const { productCode, rateCode } = params;
    localStorage.setItem(`fetching-${productCode}-${rateCode}`, true);

    try {
      const { data } = await axios.get('/rate-content', {
        params,
      });

      if (!data) return;

      let payload = {
        productCode,
        rateCode,
        bookingId,
        rateContent: {
          ...data,
          lastFetched: moment().toISOString(),
          lastFetchedParams: params,
          agentId: params.agentId,
        },
      };

      dispatch(setHotelsRateContent(payload));
    } catch (e) {
      console.error(e);
    } finally {
      localStorage.removeItem(`fetching-${productCode}-${rateCode}`);
    }
  };

export const { setPhone, setHotels, setHotelsRateContent } =
  belmondSlice.actions;

export default belmondSlice.reducer;
