import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotelsRateContent } from '../../redux/slices/belmondSlice/belmondSlice';
import useAxios from '../useAxios/useAxios';
import useLocalisedMoment from '../useLocalisedMoment/useLocalisedMoment';

const useRateContent = (
  rateCode,
  productCode,
  { startDate, endDate, numAdults = 2, agentId, id: bookingId }
) => {
  const dispatch = useDispatch();
  const moment = useLocalisedMoment();
  const axios = useAxios();
  const [rateContent, setRateContent] = useState({});
  const [loading, setLoading] = useState(true);

  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const rateContentData = useSelector(
    (state) => state.belmond.hotelsRateContent
  );

  const bookingRateContentData = useSelector(
    (state) => state.belmond.bookingRateContent
  );

  const highestAgentCommissionEnabled = useSelector(
    (state) => state.appSettings.featureFlags.highestAgentCommissionEnabled
  );

  const agentCommission =
    useSelector((state) => {
      const rateAgentCommission = rateContent?.commissionablePercentage;
      if (highestAgentCommissionEnabled) {
        return state.belmond.agents[agentId]?.commission;
      } else {
        return rateAgentCommission;
      }
    }) || 0;

  useEffect(() => {
    const fetchContent = async () => {
      // we don't want to fetch content for past dates
      if (moment(startDate).isBefore(moment(), 'day')) {
        return;
      }

      // end date will not be defined when the user is selecting dates
      if (!rateCode || !productCode || !endDate) {
        setLoading(false);
        return;
      }

      const params = {
        rateCode,
        startDate,
        endDate,
        numAdults,
        productCode,
        agentId,
        lang: currentLanguage,
      };

      let currentContent;
      if (bookingId) {
        currentContent = bookingRateContentData[productCode]?.[bookingId];
      } else {
        currentContent = rateContentData[productCode]?.[rateCode];
      }

      if (currentContent) {
        setRateContent(currentContent);
        setLoading(false);
      }

      const isFetching = Boolean(
        localStorage.getItem(`fetching-${productCode}-${rateCode}`)
      );

      const lastFetched = moment(currentContent?.lastFetched);
      const now = moment();
      const diff = currentContent?.lastFetched
        ? now.diff(lastFetched, 'minutes')
        : 6;

      const lastParams = currentContent?.lastFetchedParams || {};
      const paramsChanged =
        JSON.stringify(lastParams) !== JSON.stringify(params);

      const isCached =
        currentContent && diff < 5 && agentId === currentContent.agentId;

      if ((paramsChanged || !isCached) && !isFetching) {
        setLoading(true);
        try {
          localStorage.setItem(`fetching-${productCode}-${rateCode}`, 'true');
          await dispatch(
            fetchHotelsRateContent(params, moment, axios, bookingId)
          );
        } catch (e) {
          console.error(e);
        } finally {
          localStorage.removeItem(`fetching-${productCode}-${rateCode}`);
        }
      } else if (!isFetching) {
        setLoading(false);
      }
    };

    fetchContent();
  }, [
    dispatch,
    numAdults,
    productCode,
    rateCode,
    rateContentData,
    bookingRateContentData,
    moment,
    axios,
    currentLanguage,
    agentId,
    startDate,
    endDate,
    bookingId,
  ]);

  return { rateContent, loading, agentCommission };
};

export default useRateContent;
