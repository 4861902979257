import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PermanentModal from '../../../../components/PermanentModal/PermanentModal';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import Payment from './Payment';
import styles from './PaymentSwitcher.module.css';

const PaymentSwitcher = forwardRef(
  ({ onPaymentOptionChange, paymentProps, threedsModalOpen }, ref) => {
    const { t } = useTranslate();
    const payNowRef = useRef();
    const payAtHotelRef = useRef();
    const payNowEnabled = !!paymentProps.payNowData;
    const [paymentOption, setPaymentOption] = useState(
      payNowEnabled ? null : 'payAtHotel'
    );

    useImperativeHandle(ref, () => ({
      submit: () =>
        payNowEnabled && paymentOption === 'payNow'
          ? payNowRef.current.submit()
          : payAtHotelRef.current.submit(),
      reset: () => {
        if (payNowEnabled) {
          payNowRef.current.reset();
        }
        payAtHotelRef.current.reset();
      },
    }));

    const handlePaymentMethods = (paymentOption) => {
      if (!payNowEnabled) return;
      if (paymentOption === 'payNow') {
        payAtHotelRef.current.close();
        payNowRef.current.open();
      } else {
        payNowRef.current.close();
        payAtHotelRef.current.open();
      }

      setPaymentOption(paymentOption);
      onPaymentOptionChange(paymentOption);
    };

    return (
      <div>
        {payNowEnabled && (
          <div className={styles.PaymentSwitcher__paymentContainer}>
            <div className={styles.PaymentSwitcher__title}>PAY NOW</div>{' '}
            <div className={styles.PaymentSwitcher__infoText}>
              You will pay securely for your stay now.
            </div>
            <PermanentModal
              isModal={paymentOption === 'payNow' && threedsModalOpen}
            >
              {threedsModalOpen && <h2>{t('Secure authentication')}</h2>}
              <Payment
                ref={payNowRef}
                {...paymentProps}
                openFirstPaymentMethod={false}
                forceShowingPaymentMethodCheckBox={payNowEnabled}
                ariaLabelPrefix="payNow"
                onClick={() =>
                  paymentOption !== 'payNow' && handlePaymentMethods('payNow')
                }
              />
            </PermanentModal>
          </div>
        )}

        <div className={styles.PaymentSwitcher__paymentContainer}>
          <div className={styles.PaymentSwitcher__title}>PAY AT THE HOTEL</div>
          <div className={styles.PaymentSwitcher__infoText}>
            Your credit card will not be charged – we only need it to guarantee
            your booking. You will make the payment yourself when you check-in.
          </div>
          <PermanentModal
            isModal={paymentOption === 'payAtHotel' && threedsModalOpen}
          >
            {threedsModalOpen && <h2>{t('Secure authentication')}</h2>}
            <Payment
              ref={payAtHotelRef}
              {...paymentProps}
              payNowData={null}
              openFirstPaymentMethod={!payNowEnabled}
              forceShowingPaymentMethodCheckBox={payNowEnabled}
              ariaLabelPrefix="payAtHotel"
              onClick={() =>
                paymentOption !== 'payAtHotel' &&
                handlePaymentMethods('payAtHotel')
              }
            />
          </PermanentModal>
        </div>
      </div>
    );
  }
);

PaymentSwitcher.propTypes = {
  onPaymentOptionChange: PropTypes.func.isRequired,
  paymentProps: PropTypes.object.isRequired,
  threedsModalOpen: PropTypes.bool,
};

export default PaymentSwitcher;
