import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorView from '../../views/ErrorView/ErrorView';

const handleError = (error, errorInfo) => {
  // Ignore ResizeObserver errors
  if (error.message?.includes('ResizeObserver loop')) {
    return;
  }

  error.message = `Error Page - ${error.message}`;
  console.error(error);

  // Send error to Datadog
  datadogRum.addError(error, {
    errorInfo,
  });
};

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorView} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
